@import '../varible.scss';


.modal__overlay {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 5, 0.7);
    width: 100vw;
    height: 100vh;
    display: none;
}

.active {
    display: block;
}

.content {
    background-color: $ColorGreen;
    width: 100px;
    height: 100px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    border-radius: 100px;
    border: 5px solid $ColorWhite;
    background: url(../image/png-clipart-telephone-logo-iphone-telephone-call-smartphone-phone-electronics-text.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: shake 2s infinite;
    cursor: pointer;

    button {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &:hover~.content-text {
        opacity: 1;

    }
}

.content-text {
    pointer-events: none;
    background-color: $ColorGreen;
    position: fixed;
    left: 1%;
    bottom: 12%;
    z-index: 100;
    color: $ColorWhite;
    padding: 50px;
    border-radius: 20px;
    border: 5px solid $ColorWhite;
    opacity: 0;
    transition: 0.7s;
    animation: show 5s infinite;

    @media(max-width:500px) {
        display: none;
    }
}

.modal__window-call {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 600px;
    padding: 100px 10px;
    transform: translate(-50%, -50%);
    background-color: $ColorWhite;
    z-index: 100;
    border-radius: 20px;

    @media (max-width:500px) {
        width: 80%;
    }

    .modal__window-btn {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal__form {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;

        .modal__window-close {
            display: inline-block;
            width: 50px;
            height: 50px;
            background-color: $ColorGreen;
            border: 2px solid $ColorWhite;
            position: absolute;
            right: -5%;
            top: -5%;
            border-radius: 100%;
            cursor: pointer;
        }

        @media (max-width:500px) {
            display: flex;
            flex-direction: column;
        }

        p {
            text-align: center;
            font-size: 25px;
            margin-bottom: 70px;
        }

        div {
            input {
                width: 100%;
                outline: none;
                border: none;
                padding: 15px 5px;
                font-size: 18px;
                border-radius: 20px;
                text-align: center;
                background: $ColorGrey;
            }
        }
    }
}


@keyframes shake {

    0%,
    100% {
        transform: rotate(0deg)
    }

    25%,
    75% {
        transform: rotate(20deg)
    }

    50% {
        transform: translateX(10px);
    }
}