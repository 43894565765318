@import '../varible.scss';

.btn-4 {
    background-color: $ColorGreen;
    background-image: linear-gradient(315deg, $ColorGreen 0%, #a8e496 74%);
    line-height: 42px;
    padding: 5px 40px;
    border: none;
    border-radius: 15px;
    color: $ColorBlack;
    font-family: $HeaderFontFamily;
    font-size: 18px;
    font-weight: 500;
    transition: all 1s;
    cursor: pointer;

    &:hover {
        background-color: #2be443;
        background-image: linear-gradient(315deg, #5de83e 0%, #03c8a8 74%);
        transition: all 1s;
    }
}
