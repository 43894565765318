@import '../varible.scss';

.project {
    height: calc(100vh - HeaderHeigth);
    background-color: $ColorBackGround;

    &__container {
        padding-top: 170px;

        h3 {
            text-align: center;
            font-size: 50px;
            margin-bottom: 60px;

            span {
                color: $ColorGreen;
                font-size: 55px;
            }
        }

        .project__content-wrap {
            display: grid;
            grid-template-columns: repeat(3, 3fr);

            @media (max-width:1000px) {
                display: flex;
                flex-direction: column;
            }

            .project__content {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;


                .project__user-name {
                    font-size: 35px;
                    margin-bottom: 40px;
                    margin-top: 20px;

                }


                .project__user-image {
                    img {
                        max-width: 350px;
                        max-height: 230px;
                    }
                }

                .project__user-discription {
                    display: block;
                    max-width: 250px;
                    font-size: 18px;
                    margin-top: 100px;
                }
            }
        }

        .project__return-prjct-page {
            display: flex;
            flex-direction: column;
            margin-top: 100px;
            align-items: center;

            .project__return-prjct-page-title {
                font-size: 35px;

            }

        }

    }
}