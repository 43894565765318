// Fonts 
$HeaderFontFamily: 'Roboto';


// Colors
$ColorWhite: rgb(255, 255, 255);
$ColorBlack: rgb(0, 0, 0);
$ColorGreen: rgb(31, 120, 31);
$ColorGrey: rgb(183, 183, 183);
$ColorBackGround : rgb(235, 253, 235);

// calc header and footer
$HeaderHeigth: 127px;
$FooterHeigth: 258px;