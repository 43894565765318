@import '../varible.scss';

.program {
    background-color: $ColorBackGround;

    .program__sub-title {
        text-align: justify;      /* Вирівнювання тексту по ширині для рівномірного розподілу слів */
        max-width: 800px;         /* Обмежуємо ширину текстового блоку для кращої читабельності */
        margin: 0 auto;           /* Центруємо блок по горизонталі */
        line-height: 1.6;         /* Додаємо висоту рядків для зручнішого читання */
        padding: 20px 10px;       /* Додаємо внутрішні відступи для більш гармонійного розміщення тексту */
        font-size: 18px;          /* Оптимальний розмір шрифту для тексту */
        font-weight: 400;         /* Зберігаємо стандартну вагу шрифту для основного тексту */
    
        h3 {
            text-align: center;   /* Вирівнювання заголовка по центру */
            margin-bottom: 20px;  /* Відступ між заголовком і основним текстом */
            font-size: 35px;      /* Розмір шрифту заголовка */
            line-height: 1.2;     /* Оптимальна висота рядка заголовка */
            color: $ColorGreen;   /* Використовуємо зелений колір для заголовка */
        }
    
        span {
            font-weight: 700;     /* Виділяємо текст у тегу `span` */
            font-size: 20px;      /* Робимо текст у тегу `span` більшим */
            color: $ColorGreen;   /* Підкреслюємо важливість за допомогою кольору */
        }
    }
    


    &__container {
        padding-top: 150px;


        .program__main {
            display: flex;
            gap: 40px;

            .program__main-img {
                max-width: 400px;
            }

            .program__sub-title {
                font-size: 25px;
                font-weight: 500;
                line-height: 35px;

                h3 {
                    color: $ColorGreen;
                    font-weight: 900;
                    text-align: center;
                    font-size: 40px;
                    margin-bottom: 50px;
                    line-height: 60px;
                }

                span {
                    font-size: 40px;
                    color: $ColorGreen;
                    font-weight: 900;
                }

            }

            @media (max-width:1000px) {
                flex-direction: column;
                align-items: center;
            }
        }

        .program__content {
            margin-top: 100px;

            .program__content-items:nth-child(2n) {
                justify-content: flex-end;
            }

            .program__content-items {
                display: flex;
                align-items: center;
                margin-top: 50px;
                margin-bottom: 50px;

                @media (max-width:500px) {
                    flex-direction: column;
                    text-align: center;

                    h3 {
                        text-align: center;
                    }
                }





                .program__content-wrapp {
                    h3 {
                        margin-bottom: 50px;
                        font-size: 40px;
                        color: $ColorGreen;
                        font-weight: 1000;

                    }

                    p {
                        font-size: 20px;
                        display: block;
                        max-width: 600px;
                        line-height: 30px;
                    }

                }

                .program__content-image {
                    max-width: 150px;
                }



            }

        }
    }
}