@import '../varible.scss';

.header {
    background-color: $ColorWhite;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 20px;
        border-radius: 20px;     

    }

    &__logo {
        margin-right: 5px;
        width: 120px;

        img {
            width: 100%;
        }
    }

    &__contacts {
        .contacts__list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            

            .list-item {
                .item-link {
                    font-size: 16px;
                    font-weight: 900;
                }
            }

            @media (max-width:1200px) {
                display: none;
            }
        }


        .item-link {
            color: $ColorBlack;
            font-family: $HeaderFontFamily;
            font-size: 16px;
            font-weight: 200;


            &:hover {
                color: #2be443;
                transition: all 0.3s;
            }


        }
    }


    &__nav-list {
        display: flex;
        gap: 20px;
        white-space: nowrap;

        .item-link {
            color: $ColorBlack;
            font-family: $HeaderFontFamily;
            font-size: 17px;
            font-weight: 500;

            &:hover {
                color: $ColorGreen;
                transition: all 0.1s;
                border-bottom: 3px solid $ColorGreen;

            }
        }
    }

    &__mobile-menu {
        display: none;

    }



}

// mobile
@media (max-width: 1000px) {
    .header {


        &__mobile-menu {
            padding: 5px 0px;
            display: flex;
            text-align: center;
            height: 0vh;

            nav {
                display: flex;
                flex-direction: column;
                gap: 50px;
                max-height: 0vh;
                opacity: 0;
                overflow: hidden;
                transition: max-height 1s ease;

                .nav-list-item {
                    .item-link {
                        font-size: 20px;
                        color: $ColorBlack;
                        font-weight: 800;
                    }
                }
            }

            &.active {
                height: 100vh;
                overflow-y: hidden;

            }


            &.active nav {
                max-height: 100vh;
                opacity: 1;
                height: 100vh;
            }
        }

        &__contacts {
            display: none
        }

        &__nav-list {
            display: none;
        }

        .header-btn {
            display: none;
        }

        // button
        &__mobile-menu-trigger {
            display: flex;
            flex-direction: column;
            gap: 6px;
            cursor: pointer;




            span {
                display: inline-block;
                width: 35px;
                height: 4px;
                background-color: $ColorBlack;
                transition: 0.5s all;

                &.transform {
                    opacity: 0;
                }

                &.rotate {
                    position: absolute;
                    transform: rotate(40deg);
                    transition: 0.5s all;
                    background-color: $ColorGreen;
                }

                &.rotate-second {
                    position: absolute;
                    transform: rotate(140deg);
                    transition: 0.5s all;
                    background-color: $ColorGreen;
                }
            }
        }




    }
}