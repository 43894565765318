@import '../varible.scss';


.studies {
    margin-top: 100px;
    background: url(../image/bg-why-us.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $ColorBlack;
        position: absolute;
        opacity: 0.3;
        z-index: -1;
    }



    &__container {
        padding-top: 100px;
        padding-bottom: 150px;


        h2 {
            text-align: center;
            color: $ColorWhite;
            font-size: 40px;
            margin-bottom: 100px;
            margin-top: 100px;

            span {
                color: $ColorGreen;
            }

        }

        div {
            text-align: center;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(3, 3fr);
            border-radius: 20px;
            padding: 10px;
            z-index: 12;
            gap: 50px;

            @media (max-width:1000px) {
                display: flex;
                flex-direction: column;
            }


            h4 {
                font-size: 25px;
                color: $ColorGreen;
            }

            p {
                font-size: 20px;
                font-weight: 800;
            }

            div {
                background-color: $ColorWhite;
                max-width: 500px;
                height: 300px;
                display: flex;
                flex-direction: column;


            }

        }
    }
}