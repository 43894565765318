// Определяем основные цвета
$primary-color: #ffffff; // белый
$secondary-color: #ff6699; // розовый
$accent-color: #66ccff; // голубой

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 8px; // скругляем углы изображений для более мягкого вида
}

li,
a {
    list-style: none;
    text-decoration: none;
    color: $secondary-color;

    &:hover {
        color: $accent-color; // цвет при наведении
    }
}

body {
    font-family: 'Comic Sans MS', 'Arial Rounded', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
    background-color: $primary-color; // задаем цвет фона
}

.container {
    max-width: 1300px;
    padding: 0px 15px 0px 15px;
    margin: 0 auto;

    @media (max-width: 1200px) {
        max-width: 1000px;
    }

    @media (max-width: 1000px) {
        max-width: 750px;
    }

    @media (max-width: 700px) {
        max-width: 500px;
        padding: 0px 10px; // уменьшаем отступы на меньших экранах
    }
}

// Стили для заголовков и текста
h1, h2, h3, h4, h5, h6 {
    color: $secondary-color;
    margin-bottom: 15px;
}

p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 15px;
}
