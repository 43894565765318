@import '../varible.scss';

.job {
    background-color: $ColorBackGround;
    padding: 20px; // Добавляем отступы для всего блока

    &__container {
        padding-top: 175px; // Увеличиваем отступ сверху на 25 пикселей
        max-width: 1200px; // Ограничиваем ширину контейнера для лучшего восприятия
        margin: 0 auto;

        .job__title {
            text-align: center;
            font-size: 40px;
            margin-bottom: 50px;
            color: $ColorBlack; // Компанія черным

            span {
                color: $ColorGreen; // Inter-Web SoftTech зеленым
                font-size: 45px;
            }
        }

        .job__content-wrap {
            margin-bottom: 50px;
            background-color: #f9f9f9; // Светлый фон для контраста
            padding: 20px;
            border-radius: 10px; // Скругленные углы

            .job__intro {
                font-size: 18px;
                line-height: 1.6;
                margin-bottom: 20px; // Добавляем отступы между абзацами
            }

            .job__benefits {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 20px; // Добавляем отступ снизу

                li {
                    margin-bottom: 10px;
                    font-size: 18px; // Увеличиваем размер шрифта
                    line-height: 1.6;
                    position: relative;
                    padding-left: 30px; // Добавляем отступ слева для иконки

                    &:before {
                        content: '✔'; // Иконка перед каждым пунктом
                        position: absolute;
                        left: 0;
                        color: $ColorGreen;
                        font-size: 20px; // Размер иконки
                    }
                }
            }

            p {
                font-size: 18px;
                line-height: 1.6;
                margin-bottom: 20px; // Добавляем отступы между абзацами
            }

            .job__additional-offer {
                font-size: 18px;
                line-height: 1.6;
                margin-top: 20px;
                padding: 20px;
                background-color: #e8f5e9; // Легкий зеленый фон для выделения
                border-left: 5px solid $ColorGreen; // Левый бордер зеленого цвета
                border-radius: 5px; // Скругленные углы
            }
        }

        .job__examples {
            margin-bottom: 50px;

            &-title {
                font-size: 30px;
                margin-bottom: 20px;
                text-align: center;
            }

            &-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                }

                .job__example {
                    padding: 20px;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    text-align: center;
                    background-color: #fff; // Белый фон для контраста

                    h4 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 10px;
                        font-size: 16px; // Увеличиваем размер шрифта
                        line-height: 1.4;
                    }

                    a {
                        color: $ColorGreen;
                        text-decoration: underline;
                    }
                }
            }
        }

        .job__return {
            text-align: center;

            &-title {
                font-size: 25px;
                margin-bottom: 20px;
            }

            .job-btn {
                font-size: 18px;
                padding: 10px 20px;
            }
        }
    }
}
