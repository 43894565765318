@import '../varible.scss';

.about-us {
    min-height: calc(100vh - $HeaderHeigth);
    margin-top: 100px;
    background: url(../image/abou-image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -2;

    ::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0.1;
        z-index: -1;

    }

    &__container {
        padding-top: 100px;
        display: flex;
        flex-direction: column; /* Для лучшей адаптации под мобильные устройства */
        align-items: center; /* Центрирование содержимого */
    }

    &__title {
        text-align: center;
        font-size: 50px;
        color: $ColorWhite;
        text-shadow: -1px -1px 30px rgba(0, 0, 0, 1);

        span {
            color: $ColorGreen;
            font-size: 60px;
            font-weight: 900;
        }
    }

    &__sub-title {
        margin-top: 50px; /* Уменьшили отступ для более компактного вида */
        font-size: 30px;
        color: $ColorWhite;
        letter-spacing: 0px;
        text-align: center;
        text-shadow: -1px -1px 30px rgba(0, 0, 0, 1);

        span {
            color: $ColorGreen;
            font-size: 60px;
            font-weight: 900;
        }
    }
}
