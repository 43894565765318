@import '../varible.scss';





.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;

}

.menu {
    z-index: 9000;
    display: none;
    border-radius: 20px;
    text-align: center;
    max-width: 500px;
    height: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    p {
        font-size: 25px;
    }


    &__close-modal {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-color: $ColorGreen;
        border: 2px solid $ColorWhite;
        position: absolute;
        right: -5%;
        top: -5%;
        border-radius: 100%;
        cursor: pointer;
    }


    .form {
        margin-top: 50px;
        display: flex;
        gap: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        input {
            outline: none;
            border: none;
            border-radius: 20px;
            padding: 10px 15px;
            font-size: 25px;
            background-color: $ColorGrey;
            width: 100%;
            min-width: 300px;

            &:focus {
                border: 2px solid $ColorGreen;
                border-radius: 20px;
            }
        }
    }


    .menu-btn {
        width: 100%;
        margin-top: 50px;
    }

    @media (max-width:1000px) {
        .menu-btn {
            display: block;
        }
    }
}

.active-сlose {
    display: none;
}

.open {
    display: block;
}