@import '../varible.scss';

.our-program {
    background-color: $ColorBackGround;
    min-height: calc(100vh - $HeaderHeigth);

    &__container {
        margin-top: 150px;

        .title {
            text-align: center;
            font-size: 35px;

            span {
                color: $ColorGreen;
            }
        }



    }

    &__tab {
        margin-top: 100px;

        .tab-content {
            margin-top: 50px;

            .tab-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                margin-bottom: 20px;

                .tabs-crasshair {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    transition: all 1s;

                    :first-child {
                        display: block;
                        background-color: $ColorGreen;
                        width: 30px;
                        height: 7px;

                    }

                    :nth-child(2) {
                        display: block;
                        background-color: $ColorGreen;
                        width: 30px;
                        height: 7px;
                        transform: rotate(90deg);
                        position: absolute;

                    }
                }

            }

            .tab-title {
                cursor: pointer;
                font-size: 26px;
                font-weight: 800;
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                    transition: all 0.5s;
                }
            }


            .tabs-content-hide {
                font-size: 20px;
                padding: 0 10px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 1s ease, padding 0.5s ease;
                border-bottom: 8px solid $ColorGreen;
                opacity: 0;
                transition: all 1s;
            }


        }
    }

}

.visible {
    max-height: 600px !important;
    padding: 50px 10px !important;
    overflow: visible !important;
    background-color: #EBE6FA;
    opacity: 1 !important;
}

.rotate {
    transform: rotate(45deg) !important;
    transition: all 1s;
}