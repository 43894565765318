@import "../varible.scss";

.contact-us {
  background-color: $ColorBackGround; /* Це можна залишити або видалити, якщо тільки зображення */
  background-image: url('../image/bg-why-us.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
  

  &__container {
    padding-top: 150px;
    padding-bottom: 150px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
      text-align: center;
    }

    .contact-us__content {
      margin-top: 250px; /* Ми вже змінили це раніше */
      margin-bottom: 130px;
      background-color: rgba(255, 255, 255, 0.8); /* Білий фон з прозорістю 80% */
      padding: 20px; /* Відступи всередині фрейму */
      border-radius: 10px; /* Скруглені кути */
      

      @media (max-width: 1000px) {
        margin-top: 10px;
      }

      .contact-us__title {
        text-align: left;
        font-size: 50px;

        @media (max-width: 1000px) {
          text-align: center;
        }

        span {
          color: $ColorGreen;
          font-size: 60px;
        }
      }

      .contact-us__social {
        .contact-us__sub-title {
          font-size: 34px;
          line-height: 70px;
         
        }

        img {
          width: 30px;
        }
      }
    }
  }

  &__form {
    background-color: $ColorWhite;
    padding: 100px 15px;
    border-radius: 20px;
    border: 2px solid $ColorGreen;
    max-width: 60%;

    @media (max-width: 1000px) {
      margin-top: 10px;
      max-width: 100%;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      input {
        padding: 20px 15px;
        font-size: 25px;
        border-radius: 20px;
        border: none;
        width: 80%;
        outline: none;

        &:focus {
          border: 2px solid $ColorGreen;
        }
      }

      .contact-us__form-button {
        width: 100%;
        display: flex;
        justify-content: center;

        .contact-us__form-btn {
          margin-top: 50px;
          width: 85%;
        }
      }
    }

    .form-title {
      text-align: center;
      margin-bottom: 50px;
      font-size: 40px;

      span {
        color: $ColorGreen;
      }
    }
  }
}
