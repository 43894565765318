@import '../varible.scss';

.swiper {
    margin-top: 100px;

    .swiper-wrapper {

        .swiper-slide {
            .slide__container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                height: 720px;
                padding: 50px 20px;
                background-color: $ColorWhite;
                border-radius: 20px;
                cursor: pointer;
                transition: 0.5s;

                &:hover {
                    background-color: $ColorGreen;
                    transition: 0.5s;
                    color: $ColorWhite;

                    .slider__ages {
                        background-color: $ColorWhite;
                        color: $ColorBlack;
                    }

                }


                .slider__title {
                    font-size: 40px;
                    font-weight: 900;
                }

                .slider__ages {
                    font-size: 20px;
                    display: inline-block;
                    background-color: $ColorGreen;
                    border-radius: 20px;
                    padding: 10px 20px;
                    color: $ColorWhite;
                    margin-top: 30px;
                }

                .slider__sub-title {
                    font-size: 18px;
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                }

                .slider__image {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    opacity: 0.2;
                    z-index: -10;

                    img {
                        width: 350px;
                    }
                }

                .slider__button {
                    cursor: pointer;
                    position: absolute;
                    bottom: 5%;
                }



            }
        }
    }
}