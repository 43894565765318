@import '../varible.scss';

.about {

    background-image: url(../image/bg-abt.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;


    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        z-index: -2;
    }

    &__container {
        padding: 150px 20px;
        z-index: 1;
    }


    &__text-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 700px;
    }

    &__title {
        font-size: 50px;
        color: $ColorWhite;

    }

    &__sub-title {
        font-size: 25px;
        color: $ColorWhite;
    }

    &__sub-title-active {
        font-size: 25px;
        color: $ColorWhite;

        span {
            font-size: 40px;
        }
    }

    &__form-container {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        gap: 30px;


        input {
            width: 450px;
            border-radius: 15px;
            border: none;
            padding: 10px 15px;
            font-family: 'Roboto';
            font-size: 20px;
            outline: none;
        }
    }

    &__form-btn {
        width: 180px;
    }

    &__feedback {
        margin-top: 50px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    &__feedback-content {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: $ColorWhite;
        padding: 30px 35px;
        white-space: nowrap;



        p {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: $ColorBlack;
            font-size: 20px;
            font-weight: 600;



            span {
                color: $ColorGreen;
                font-size: 50px;
            }

        }
    }
}

@media (max-width:1000px) {
    .about {
        &__form-container {
            input {
                width: 100%;
            }
        }

        &__feedback {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }


    }
}