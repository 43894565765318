@import '../varible.scss';

.info {
    background-color: $ColorBackGround;
    padding: 100px 0px;
    background-image: url('../image/TET_6056-Edit+.jpg');  // Добавляем изображение
    background-position: center;  // Центрируем изображение
    background-size: cover;       // Масштабируем изображение
    background-repeat: no-repeat; // Избегаем повторения изображения

    &__container {
        h3 {
            text-align: center;
            font-size: 30px;
            margin-top: 20px;

            span {
                color: $ColorGreen;
                font-size: 35px;
            }
        }

        text-align: center; // Применяем центрирование для всех устройств
    }

    &__title {
        text-align: center;
        font-size: 40px;
        color: $ColorBlack;

        span {
            color: $ColorGreen;
            font-size: 50px;
        }
    }

    &__programs {
        display: flex;
        justify-content: space-between;  // Равномерное распределение карточек
        flex-wrap: wrap;  // Карточки переносятся на следующую строку, если не помещаются
        gap: 20px;  // Отступы между карточками
    }

    .swiper {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;  // Выравнивание кнопки по низу
        padding: 50px 20px;
        border: 3px solid $ColorGrey;
        border-radius: 20px;
        margin-top: 80px;
        background-color: $ColorGreen;
        opacity: 0.7;  // Прозрачность карточек
        width: calc(25% - 40px);  // Занимает 25% ширины
        margin: 20px 0;
        text-align: center;
        box-sizing: border-box;

        // Делаем изображение и текст блочно и фиксируем высоту, чтобы кнопка всегда была внизу
        .info__programs-image {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            img {
                max-width: 150px;  // Размер изображений
            }
        }

        .info__programs-title {
            font-size: 25px;
            font-weight: 700;
            color: $ColorWhite;
            text-shadow: -1px -1px 30px rgba(0, 0, 0, 1);
            margin-bottom: 20px;
        }

        .info__programs-sub-title {
            font-size: 20px;
            font-weight: 500;
            color: $ColorWhite;
            text-shadow: -1px -1px 30px rgba(0, 0, 0, 1);
            margin-top: 20px;
            flex-grow: 1;  // Увеличиваем текст, чтобы он занимал оставшееся пространство
        }

        .info__button-age {
            margin-top: auto;  // Размещаем кнопку внизу карточки
            padding: 10px 0;
        }

        
    }
}

@media (max-width: 1000px) {
    .info {
        &__content {
            padding: 50px 20px;
            border: 3px solid $ColorGrey;
            border-radius: 20px;
            margin-top: 80px;
            background-color: $ColorGreen;
            opacity: 0.7;  // Прозрачность карточек
            width: 100%;  // Карточки занимают всю ширину на мобильных устройствах
            margin: 10px 0;
        }

        &__programs {
            display: block;
        }

        .info__programs-image {
            display: flex;
            justify-content: center;
            margin-top: 60px;

            img {
                max-width: 200px;
            }
        }

        .swiper {
            display: none;
        }
    }
}
