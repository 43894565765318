@import '../varible.scss';

.feedback {
    background-color: $ColorBackGround;

    &__container {
        padding-top: 150px;

        h3 {
            text-align: center;
            font-size: 40px;
            margin-bottom: 50px;

            span {
                color: $ColorGreen;
                font-size: 45px;
            }
        }

        .feedback__content {
            display: grid;
            grid-template-columns: repeat(4, 4fr);
            gap: 50px;

            @media (max-width:1000px) {
                grid-template-columns: repeat(2, 2fr);
            }

            @media (max-width:480px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .feedback__content-wrap {
                cursor: pointer;
                padding: 15px 5px;
                border-radius: 20px;
                transition: 0.6s;

                &:hover {
                    background-color: rgb(224, 224, 224);
                    transform: scale(1.1);
                    transition: all 0.6s;
                }

                .feedback__person {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .feedback__image {
                        width: 50px;

                        img {
                            border-radius: 100%;
                            height: 50px;
                            width: 50px;
                        }
                    }

                    .person-name {
                        font-size: 18px;
                        font-weight: 600;
                    }


                }

                .person-discription {
                    display: block;
                    font-size: 17px;
                    line-height: 25px;
                    max-width: 300px;
                }
            }

        }
    }
}